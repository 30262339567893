








































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Global } from '@/store';

@Component({
    components: {}
})

export default class CommissionTable extends Vue {
    @Prop() headers;
    @Prop() devices;
    @Prop() title;
    @Prop() isLoading;

    @Global.State('lang') lang;

    pagination = {
        rowsPerPage: null,
        totalItems: null
    };
    pages = 0;

    classes = [];

    mounted(){
        this.pagination.rowsPerPage = this.devices.length ? 5 : null;
        this.pagination.totalItems = this.devices.length ? this.devices.length : null;
        this.pages = this.pagination.rowsPerPage == null || this.pagination.totalItems == null ? 0 : Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    }

} 
